/**
*
* Composants spécifique au CMS e-majine
*
**/


/* -----------------
Champ ajout d'image
------------------- */
#html5Behaviorspicture_input { margin-left: 200px; }
.imagePreview {
	overflow: hidden;
	width: 200px;
	height: 200px;
}
.imagePreview img {
	max-width: none;
}
.imagePreviewCropLayer {
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: 1000;
	margin: -200px 0 0 -300px;
	width: 600px;
	height: 410px;
	padding: 10px;
	background: #fff;
	box-shadow: 0 5px 10px rgba(0,0,0,.7);
	-moz-box-shadow: 0 5px 10px rgba(0,0,0,.7);
	-webkit-box-shadow: 0 5px 10px rgba(0,0,0,.7);
}
.imagePreviewCropLayer > span {
	float: right;
	color: #31a5da;
	padding: 5px 10px;
	margin: 10px 0 0 10px;
	font-size: 12px;
	border: 1px solid #31a5da;
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	cursor: pointer;
}
.imagePreviewCropLayer > span:last-child {
	border: 1px solid #fff;
}
.imagePreviewCropLayer > span:hover {
	color: #fff;
	background: #31a5da;
	border: 1px solid #31a5da;
}
.imagePreviewCrop {
	height: 350px;
	background: #eee;
	border: 1px solid #ddd;
	overflow-y: auto;
}
.datasFile {
	float: left;
	margin-top: 10px;
	font: bold 12px/32px Arial, Verdana, sans-serif;
}
.datasFile > span { margin-right: 20px; }
.datasFile strong { font-weight: normal; }
#fd_noir {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	display: none;
	width: 100%;
	height: 100%;
	background: url(/images/bg_mask.png);
}

.arrowBlack {
	position: absolute;
	left: 5px;
	bottom: -5px;
	display: block;
	width: 10px !important;
	height: 5px !important;
	padding: 0;
	margin: 0;
	background: url(/common_images/community/bg_arrow.png);
}
.arrowBlack.reverse, .arrowContent.reverse .arrowBlack {
	left: auto;
	right: 5px;
}
.arrowContent {
	position: absolute;
	top: -25px;
	left: 0;
	z-index: 200;
	display: none;
	padding: 0 5px;
	font-size: 11px;
	line-height: 20px;
	color: #fff;
	background: #000;
	white-space: nowrap;
}


/*===================================
=        Calendrier e-majine        =
===================================*/

#overDiv.calendar{
    margin-left: -153px;
    margin-top: 6px;
}
#overlibcontent{
    background: #fff;
    padding: 15px;
    font-size: 16px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.24);
    a{text-decoration: none;}
    .nav{
        &:before, &:after{display: none;}
    }
    caption{
        padding-top: 0;
        font-weight: 500;
        color:$body-color;
        text-align: center;
    }

}
.overlibcaption{display: none;}
.overlibclose{
    position: absolute;
    top: 0px;
    right: 0px;
    a{
        text-decoration: none;
        width: 30px;
        float: right;
        height:30px;
        border-radius: 50%;
        display: block;
        overflow: hidden;
        color: $primary;
        &:before{
            content: "\e209";
            @extend .icomoon;
            padding: 6px 0;
            text-align: center;
            display: block;
			font-size: 20px;
        }
        &:hover, &:focus{
            background: #fff;
            color:$body-color;
        }
    }
}
#overlibfooter, .overlibcaption{display: none;}

.calendar{
	caption{
	caption-side: top;
	}
	.nav{
		display:table-row;

	}
    .nav th a{
        border:none;
        &:hover, &:focus{
            border:none;
            background: transparent;
            color:  $primary;
        }
    }
     table{
        border-collapse: separate;
        border-spacing: 1px;
        text-align: center;
		table-layout: fixed;
    }
    th{font-weight: normal;	padding: 2px;}
    th, td{
            text-align: center;
        	font-size: 15px;
			min-width: 32px;
        a{
            display: block;
            border:1px solid #c2c2c2;
            padding: 2px;
            &:hover, &:focus{
                background:  $primary;
                color:#fff;
                border-color:  $primary;
            }
        }
    }
    td{

    }
    .no_day{border:none;}
    .past_day{
        background: #dfdfdf;
        a{
            color:$gray-600;
            &:hover, &:focus{
                background:  $primary;
                color:#fff;
                border-color:  $primary;
            }
        }
    }
}


/*=========================================
=            Article en bloc            =
=========================================*/

.bloc-simple, .bloc-double {
	margin-top: 0;
	margin-bottom: 0;
}
.medias, .map {
	margin-bottom: 1rem;
}
.bloc-double + .bloc-double ul, .intext_left + div ul {
	list-style-position: inside;
}
.intext_left, .intext_right {
	float: none;
	.bloc--img{
		text-align: center;
		margin-bottom: 1rem;
	}
}
.above_left, .above_right, .above_center {
	margin-bottom: 1rem;
}
.below_left, .below_right, .below_center {
	margin-bottom: 1rem;
}
.above_left, .below_left {
	text-align: left;
}
.above_right, .below_right {
	text-align: right;
}
.above_center, .below_center {
	text-align: center;
	justify-content: center;
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.6rem;
    margin-left: -0.6rem;
	.bloc--img{
		max-width: 100%;
		margin-bottom: 1rem;
		-webkit-box-flex: 0;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		padding-left:0.6rem; padding-right: 0.6rem;
	}
}


@include media-breakpoint-up(md) {
	.intext_left {
		float: left;
		margin-right: 1rem;
		.bloc--img{text-align: left;}
	}
	.intext_right {
		float: right;
		margin-left: 1rem;
		.bloc--img{text-align: right;}
	}
}

/*=====  End of Article en bloc  ======*/

/*==================================
=            Re-captcha            =
==================================*/

#recaptcha_image, .recaptchatable .recaptcha_image_cell center img, .recaptchatable .recaptcha_image_cell center, .recaptchatable .recaptcha_image_cell, .recaptchatable #recaptcha_response_field {
	height: auto !important;
	width: 100% !important;
}
#captcha .recaptchatable {
	border: none !important;
}
.recaptcha {
	max-width: 400px;
}
#recaptcha_response_field {
	border: 1px solid #cccccc !important;
	padding: 2px 5px !important;
	color: #000000 !important;
}
#recaptcha_table td + td + td {
	padding: 0 !important;
}
.recaptcha a img {
	max-width: none;
}
#recaptcha_logo, #recaptcha_privacy {
	display: none;
}

/*=====  End of Re-captcha  ======*/

/*=======================================
=            Alerte e-majine            =
=======================================*/

/* etoile pour champs obligatoire */
.obligatory{
	color: color('danger');
}
.form .invalid-feedback{display: block;}
/* Message "Champ obligatoire" */
.errorForm, .error-form{
	@extend .badge !optional;
	@extend .badge-danger !optional;
}

/* Etat invalide des champs */
.form-group.error {
	.form-control{	@extend .is-invalid !optional;}
}

/* Reste à tester */
.correct {
	@extend .alert !optional;
	@extend .alert-success !optional;
}

.errorMessage{
	@extend .alert !optional;
	@extend .alert-danger !optional;
}
/* validation de l'inscription au compte : Votre compte est maintenant actif. Un message comprenant votre login et votre mot de passe a été envoyé à l'adresse ...*/
.confirmation_subscription{
	.confirmation_validation{
		@extend .alert !optional;
		@extend .alert-success !optional;
	}
}
/*=====  End of Alerte e-majine  ======*/

/*=============================================
=            check password           =
=============================================*/

.strength_password, .confirmpasswd {
	padding-left: 10px;
}
.shortPass, .badPass, .notequalpasswd {
	color: color('danger');
}
.goodPass { 
	color: color('warning');
}
.strongPass, .equalpasswd {
	color: color('success');
}

/*=====  End of check password  ======*/
