/**
*
* Navigation
* Menu, breadcrumb, pagination, pager, ...
*
**/

/* gestion du tactile quand le menu repasse en desktop */
@include media-breakpoint-up(md) {
  .navbar-nav .nav-item:hover > .dropdown-menu, .navbar-nav .nav-item:focus > .dropdown-menu{ display: block;  }
  //à tester pour ios (bootstrap gère ca?)
  .no-touch .navbar-nav li:hover > ul.dropdown-menu{ display: none; }
  .no-touch .navbar-nav li.open > ul.dropdown-menu { display: block;}

//dropdown
.navbar-nav .dropdown-menu{margin-top:0;}
//niveau 3 positionné à droite
  .dropdown-menu .dropdown-menu{
     top: 0;
     left: 100%;
  }
}


/*=======================================
=            Animation Menu             =
=======================================*/

/* .dropdown{
    &.open, &:hover, &:focus{
        & > .dropdown-menu {
          -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
        }
    }
}

.dropdown-menu {
  transform-origin: top;
  animation-fill-mode: forwards;
  transform: scale(1, 0);
  display: block;
  transition: all 0.2s ease-out;
}
 */


/*=====  End of Animation Menu ======*/
// Menu mobile
@include media-breakpoint-down(lg) {
    .mm-menu_offcanvas {
        position: absolute !important;
    }
    .mm-navbar, .mm-panel {
        background-color: #fff !important;
    }
    .mm-navbar__title, .mm-listitem__text {
        color: $primary !important;
    }
    .mm-btn_next:after {
        border-color: $primary !important;
    }
}
.mobilebutton {
    display: flex;
    flex-direction: column;
    padding: 0;
    @include media-breakpoint-up(lg) {
        display: none;
    }
    &:hover, &:active, &:focus {
        outline: none;
    }
    &::before, &::after {
        content: "";
        width: 40px;
        height: 3px;
        background-color: #fff;
        transition: transform .1s;
    }
    span {
        display: block;
        background-image: none !important;
        width: 40px;
        height: 3px;
        background-color: #fff;
        margin: 8px 0;
    }
}
.mm-wrapper_opened {
    .mobilebutton {
        span {
            display: none;
        }
        &::before {
            transform: translate(0px,13px) rotate(45deg);
        }
        &::after {
            transform: translate(0px,10px) rotate(-45deg);
        }
    }
}
#menu-main {
    margin-bottom: 0;
    @include media-breakpoint-down(md) {
        background-color: #fff;
    }
    // Menu desktop
    @include media-breakpoint-up(lg) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        .menu-main_submenu {
            margin: 0;
            background-color: #0D234F;
            min-width: 300px;
            padding: 20px;
            .menu-main_link {
                color: white;
            }
            
            .menu-main_submenu {
                background-color: #e5e5e5;
                .menu-main_submenu {
                    background-color: #cbc9c9;
                }
            }
        }
        .menu-main-item {
            list-style-type: none;
            position: relative;
            &.hide-desktop{
                display: none;
                @include media-breakpoint-down(lg) {
                    display: block;
                }
            }
        }
        .menu-main_link {
            color: white;
            font-size: 18px;
            font-weight: bold;

            text-decoration: none;
            padding: 10px;
            display: block;

            &:hover {
                color: $secondary;
            }
        }
        > li {
            margin: 0 0 0 40px;
            > a {
                padding: 20px 0 !important;
            }
            > ul {
                position: absolute;
                top: 100%;
                left: 0;
                display: none;
                > li {
                    > ul {
                        display: none;
                    }
                    &:hover {
                        > ul {
                            display: block;
                        }
                    }
                }
            }
            &:hover {
                > ul {
                    display: block;
                }
            }
        }
    }
}
#mmenu--button {
    @include media-breakpoint-up(lg) {
        display: none
    }
}
