/**
*
* Eléments réutilisables :
* boutons, icônes, popin (lightbox, notification…), slider (styles communs)…
*
**/
body{
	min-height:100vh; /* 1 */
	display:flex; /* 2 */
	flex-direction:column; /* 3 */
}
.main{
	flex-grow:1; /* 4 */
}

/* on évite les débordements */
img{max-width: 100%;}
.map{
    max-width: 100%;
     overflow: hidden;
    > div {
        max-width: 100%;
        overflow: hidden;
    }
}


/* boutons */
.btn-prev{
    &:before{
        content: '\e603';
        @extend .icomoon;
        padding-right: 0.5rem;
        vertical-align: -2px;
    }
}
.btn-next{
    &:after{
        content: '\e602';
        @extend .icomoon;
        padding-left: 0.5rem;
        vertical-align: -2px;
    }
}
.btn-back{
    &:before{
        content: '\e609';
        @extend .icomoon;
        padding-right: 0.5rem;
        vertical-align: -2px;
    }
}

/* galerie d'images*/
.article-gallery{
    .lightbox{
        overflow: hidden;
        max-height: 12.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1.5rem;
        //création d'un colonnage pour les éléments de galerie
        @include make-col-ready();
        @include media-breakpoint-up(sm) {
           @include make-col(6);
        }
        @include media-breakpoint-up(md) {
           @include make-col(4);
        }
        @include media-breakpoint-up(lg) {
           @include make-col(3);
        }
    }
}

/* pagination*/
.pager_current_page{
    @extend .active;
}



/*==================================
=            Skip links            =
==================================*/

.skip-nav {
	position: absolute;
	padding: 0;
	list-style: none;
	z-index:9999;
	a {
		position: absolute;
		left: -7000px;
		padding: 0.5em;
		background: $gray-800;
		color: white;
		text-decoration: none;
		&:focus{
			position: static;
		}
	}
}

/*=====  End of Skip links  ======*/
