/*	--------------------------------------------------
	--------------------------------------------------
		Gestion des fonts.
		Possibilité de créer une mixin par font

	--------------------------------------------------
	--------------------------------------------------*/


$font-path:"fonts/";

/** ICOMOON **/
$font-icon-folder:"icomoon/";
$font-icon-name:"icomoon";
$font-icon-svg-id:"icomoon";


@font-face {
	font-family: $font-icon-name;
		src:url('#{$font-path}#{$font-icon-folder}#{$font-icon-name}.eot');
		src:url('#{$font-path}#{$font-icon-folder}#{$font-icon-name}.eot') format('embedded-opentype'),
			url('#{$font-path}#{$font-icon-folder}#{$font-icon-name}.ttf') format('truetype'),
			url('#{$font-path}#{$font-icon-folder}#{$font-icon-name}.woff') format('woff'),
			url('#{$font-path}#{$font-icon-folder}#{$font-icon-name}.woff2') format('woff2'),
			url('#{$font-path}#{$font-icon-folder}#{$font-icon-name}.svg##{$font-icon-svg-id}') format('svg');
		font-weight: normal;
		font-style: normal;
}

[class^="icon-"], [class*=" icon-"], .icomoon {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
  display: inline-block;
  vertical-align: middle;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


.icon-bin:before {
  content: "\e912";
}
.icon-trashcan:before {
  content: "\e912";
}
.icon-remove:before {
  content: "\e912";
}
.icon-delete:before {
  content: "\e912";
}
.icon-recycle:before {
  content: "\e912";
}
.icon-dispose:before {
  content: "\e912";
}
.icon-folder-plus:before {
  content: "\e911";
}
.icon-directory:before {
  content: "\e911";
}
.icon-folder-add:before {
  content: "\e911";
}
.icon-cog:before {
  content: "\e90b";
}
.icon-gear:before {
  content: "\e90b";
}
.icon-settings:before {
  content: "\e90b";
}
.icon-generate:before {
  content: "\e90b";
}
.icon-make:before {
  content: "\e90b";
}
.icon-options:before {
  content: "\e90b";
}
.icon-switch:before {
  content: "\e90c";
}
.icon-power:before {
  content: "\e90c";
}
.icon-linkedin:before {
  content: "\e908";
}
.icon-instagram:before {
  content: "\e909";
}
.icon-share:before {
  content: "\e904";
}
.icon-arrow-right:before {
  content: "\e602";
}
.icon-arrow-left:before {
  content: "\e603";
}
.icon-user:before {
  content: "\e604";
}
.icon-printer:before {
  content: "\e605";
}
.icon-search:before {
  content: "\e90a";
}
.icon-remove-user:before {
  content: "\e90e";
}
.icon-download:before {
  content: "\e90f";
}
.icon-megaphone:before {
  content: "\e910";
}
.icon-chevron-small-left:before {
  content: "\e607";
}
.icon-chevron-small-right:before {
  content: "\e608";
}
.icon-chevron-thin-left:before {
  content: "\e609";
}
.icon-chevron-thin-right:before {
  content: "\e60a";
}
.icon-location-pin:before {
  content: "\e60b";
}
.icon-twitter:before {
  content: "\e60c";
}
.icon-locked:before {
  content: "\e90d";
}
.icon-secure:before {
  content: "\e90d";
}
.icon-calendar:before {
  content: "\e902";
}
.icon-pen:before {
  content: "\e903";
}
.icon-zephyr:before {
  content: "\e905";
}
.icon-close:before {
  content: "\e209";
}
.icon-done:before {
  content: "\e28a";
}
.icon-mail:before {
  content: "\e601";
}
.icon-youtube:before {
  content: "\e906";
}
.icon-tripadvisor:before {
  content: "\e900";
}
.icon-pinterest:before {
  content: "\e901";
}
.icon-phone:before {
  content: "\f095";
}
.icon-facebook:before {
  content: "\f09a";
}
.icon-chevron-double-small-right:before {
  content: "\e60d";
}
.icon-chevron-double-small-left:before {
  content: "\e60e";
}
.icon-chevron-double-thin-left:before {
  content: "\e60f";
}
.icon-chevron-double-thin-right:before {
  content: "\e610";
}
.icon-google:before {
  content: "\e600";
}
.icon-shopping-cart:before {
  content: "\e907";
}
