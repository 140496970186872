/*	--------------------------------------------------
	--------------------------------------------------
		méthodes de publication:
	communs, actualités, articles, événements
--------------------------------------------------
--------------------------------------------------*/

/*
transition: all .3s ease;

img {
    object-fit: cover;
    font-family: "object-fit: cover;";
    height: 100%;
    width: 100%;
}

@extend .icomoon;

@include icomoon;
*/


/* HOMEPAGE */



/* HOMEPAGE FIN */


/* media (bootstrap) : présentation famille, header news detail, header event detail, structure bloc&image emajine */
.media {
    flex-wrap: wrap;
}

@include media-breakpoint-up(sm) {
    .media {
        flex-wrap: nowrap;
    }
    .media-img {
        max-width: 25%;
    }
}
