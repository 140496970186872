/*	--------------------------------------------------
	--------------------------------------------------
		Agencement des blocs structurants :
	container, header, content, sidebar, footer
--------------------------------------------------
--------------------------------------------------*/
body{
    overflow-x: hidden;
}
/**** HEADER ****/
.header {
    background-color: #010F2B;
    padding: 3px 0;
    position: relative;
    z-index: 10;
    @include media-breakpoint-down(lg) {
        padding: 14px 0;
    }
    .header_logo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo {
            max-width: 250px;
            padding: 0;
            margin: 0;
        }
    }
}

/**** BREACRUMB ****/
.breadcrumb{
    opacity: 0.5;
    a{
        text-decoration: none;
    }
}

/**** FOOTER ****/
.footer{
    padding-top: 70px;
    background-color: $primary;
    .copyright_footer{
        border-top: 1px solid hsla(0,0%,100%,.3);
        margin-top: 20px;
        padding: 20px 0;
        font-size: 14px;
    }
}
/*****NEWSLETTER*****/
#NewsLettersSubscribeMail{
    border-radius: 3px 0 0 3px;
    border: none;
}
.btn_submit_news{
    color: #fff;
    border-radius: 0 3px 3px 0;
    background-color: #449EFF;
    border: none;
    padding: 0 14px;
}
.link_linkedin_footer{
    display: block;
    background: url('/images/pic_ln.png');
    width: 21px;
    height: 21px;
}
/*****STYLE DEFAUT*****/
#sticky_teamviewer{
    position: fixed;
    bottom: -170px;
    right: -80px;
    width: 332px;
    height: 332px;
    background: url('/images/tv_sticky.png');
    z-index: 99;
    @include media-breakpoint-down(lg) {
        bottom: 0;
        right: 0;
        width: 60px;
        height: 60px;
        background: #4197f5 url('/images/tv_sticky_mobile.png') no-repeat center;
        border-radius: 10px 10px 0px 10px;
    }
}
.bloc_home_surtitle {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: $primary;
  }
.content{
    h1{
        margin-bottom: 0;
        font-size: 40px;
        font-weight: 700;
        color: $primary;
    }
    h2{
        margin-bottom: 14px;
        font-size: 30px;
        font-weight: 700;
        color: $secondary;
    }
    h3{
        margin-bottom: 14px;
        font-size: 25px;
        font-weight: 700;
        color: $secondary;
    }
    p{
        color: $secondary;
    }
    a{
        color: $primary;
    }
    .center{
        text-align: center;
    }

    ul{
        list-style: inside;
        li{
            margin-bottom: 6px;
        }
        // li:before{
        //     margin-right: 8px;
        //     content: url('/images/icn_liste.png');
        //     vertical-align: middle;
        // }
    }
    .btn{
        padding: 10px 25px;
        border-radius: 5px;
        background-color: #E5E7EC;
        border: 3px solid #E5E7EC;
        color: $primary;
        &.btn_yellow,
        &.btn-default{
            background: rgb(68,158,255);
            background: linear-gradient(90deg, rgba(68,158,255,1) 0%, rgba(60,134,221,1) 35%, rgba(51,106,181,1) 70%, rgba(32,49,99,1) 100%);
            color: #fff;
            border: none!important;
            &:hover{
                background: linear-gradient(90deg, rgba(32,49,99,1) 0%, rgba(51,106,181,1) 35%, rgba(60,134,221,1) 70%, rgba(68,158,255,1) 100%);
            }
        }
    }
    
}
.cover{
    img{
        display: block;
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}
.contain{
    img{
        display: block;
        -o-object-fit: contain;
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
/*****BREADCRUMB*****/
.breadcrumb{
    margin-top: 27px;
    li,a,span{
        color: #2A3F69;
        opacity: 0.8;
        text-decoration: none;
    }
}
/***** NEWS *****/
body.newsList{
    .content{
        position: relative;
        padding-bottom: 130px;
        &:before{
            display: block;
            position: absolute;
            bottom: 0;
            background: url('/images/bg_page_actus.jpg') no-repeat center center;
            content: '';
            width: 100%;
            height: 380px;
        }
    }
}
.news--list{
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px;
}
.news--item{
    position: relative;
    padding-bottom: 15px;
    width: 31%;
    border: none;
    margin-bottom: 30px;
    border-bottom: 5px solid $primary;
    background-color: $primary;
    @include media-breakpoint-down(lg) {
        width: 48%;
    }
    &:hover{
        border-bottom: 5px solid #449EFF;
    }
    @include media-breakpoint-down(md){
        width: 100%;
        margin-top: 0!important;
    }
    .news--item-container{
        padding: 30px 40px 0;
    }
    .news--item-img{
        height: 196px;
        width: 100%;
    }
    .news--item-link{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .news--item-date{
        display: inline-block;
        margin-bottom: 12px;
        border-radius: 4px;
        padding: 4px;
        background-color: #449EFF;
        color: $primary;
        font-size: 11px;
    }
    .news--item-title{
        font-size: 18px;
        color: #fff!important;
    }
    .news--item-desc{
        color: #fff!important;
    }
}
ul.pagination{
    justify-content: center;
    margin-bottom: 0;
    li:before{
        display: none;
    }
    li{
        margin: 0 5px;
        text-align: center;
    }
    a{
        background: none!important;
        width: 38px;
        height: 38px;
        border-radius: 50%!important;
        color: #fff;
        border-color: transparent!important;
        &.pager_current_page{
            border-color: #fff!important;
            color: #fff!important;
            font-weight: 700;
        }
    }
}
.news-detail{
    color: #0d234f;
    .news-detail--title{
        text-align: left!important;
    }
    .news-detail--date{
        display: inline-block;
        margin-bottom: 12px;
        border-radius: 4px;
        padding: 4px;
        background-color: $primary;
        color: #fff;
        font-size: 13px;
    }
    p,h2{
        color: #0d234f;
    }
}
.mu.news{
    p{
        color: #0d234f;
    }
}
#last_elt_detail_actu{
    h2,h3{
        color: #0d234f;
    }
    .events--item{
        position: relative;
        height: 95px;
        border-radius: 5px;
        &:hover{
            border-bottom: 5px solid #449EFF;
        }
        .events--item-img{
            width: 100px;
            height: 95px;
            img{
                border-radius: 5px;
            }
        }
        .events--item-title{
            flex: 1;
            padding: 0 20px;
            font-size: 14px;
            margin-bottom: 0;
        }
        .events--item-link{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}
/***** FICHE METIER *****/
.fiche_metier_top{
    margin-bottom: 90px;
}
.fiche_metier_top_thumb{
    height: 350px;
    img{
        border-radius: 10px;
    }
}
.fiche_metier_top_desc{
    h2{
        font-size: 22px;
        line-height: 28px;
    }
}
.fiche_metier_top_img {
    height: 912px;
    left: 50%;
    position: absolute;
    top: -108px;
    transform: translateX(-50%);
    width: 100vw;
    z-index: -1;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.fiche_metier_logos {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    
}
// body.fiche_metier_si #fiche_metier_savoir::before{
//     background: url('/images/bg_fiche_metier_SI.jpg') no-repeat center center;
//     background-size: cover;
// }
// body.fiche_metier_assitance #fiche_metier_savoir::before{
//     background: url('/images/bg_fiche_metier_assistance.jpg') no-repeat center center;
//     background-size: cover;
// }
// body.fiche_metier_cyber #fiche_metier_savoir::before{
//     background: url('/images/bg_fiche_metier_cyber.jpg') no-repeat center center;
//     background-size: cover;
// }
// body.fiche_metier_cloud #fiche_metier_savoir::before{
//     background: url('/images/bg_fiche_metier_cloud.jpg') no-repeat center center;
//     background-size: cover;
// }
#fiche_metier_savoir{
    position: relative;
    align-items: flex-start;
    margin-top: 207px;

    &::before {
        background-image: url(/images/svg/a-propos-background.svg);
        background-position: left 0 center;
        background-repeat: no-repeat;
        background-size: 356px 465px;
        content: "";
        height: 465px;
        left: 0px;
        position: absolute;
        bottom: 0;
        transform: translateX(-40%);
        width: 356px;
        z-index: 1;
    }
    .fiche_metier_left_bar{
        background-color: $primary;
        padding: 36px;
        border-radius: 10px;
        border: 2px solid #449EFF;
        .fiche_metier_left_bar_title{
            margin-bottom: 22px;
            font-size: 25px;
            font-weight: 700;
        }
        a{
            display: block;
            margin-bottom: 16px;
            color: $secondary;
            font-weight: 500;
            text-decoration: none;
            &.ancre_selected{
                color: #fff;
                font-size: 20px;
                font-weight: 700;
                margin-left: 20px;
            }
        }
    }
    .single_savoir{
        background-color: $primary;
        padding: 60px;
        color: #fff;
        border-bottom: 5px solid #449EFF;
        border-radius: 10px;
        @include media-breakpoint-down(md) {
            padding: 20px;
        }
        .single_savoir_title{
            display: block;
            margin-bottom: 20px;
            color: #fff;
            font-size: 25px;
            font-weight: 700;
        }
        .single_savoir_desc{
            p{
                color: #fff;
            }
            strong,a{
                color: $secondary;
            }
            
        }
    }
}
/***** PAGE ENTREPRISE *****/
.page_entreprise{
    h1{
        display: none;
    }
    .page_entreprise_title{
        margin-bottom: 0;
        font-size: 40px;
        font-weight: 700;
        color: #0D234F;
    }
    .title_page_entreprise{
        margin-bottom: 0;
        font-size: 40px;
        line-height: 48px;
    }
}
.page_entreprise_top{
    .page_entreprise_top_desc{
        h2{
            font-size: 22px;
            line-height: 28px;
        }
        p{
            color: #0d234f;
            strong{
                color: $secondary;
            }
        }
        
    }
    .top_entreprise_thumb{
        height: 600px;
        @include media-breakpoint-down(lg) {
            height: 350px;
        }
        img{
            border-radius: 10px;
            -o-object-fit: contain;
            object-fit: contain;
        }
    }
}
#strap_bloc_entreprise{
    margin-top: 60px;
    position: relative;
    
    &::before,
    &::after,
    > img {
        width: 100vw;
        height: calc(100% - 12%);
        position: absolute;
        top: 12%;
        left: 50%;
        transform: translateX(-50%);
    }

    > img {
        max-width: unset;
        z-index: -3;
        object-fit: cover;
    }

    &::before,
    &::after {
        content: '';
    }

    &::before {
        background-color: $primary;
        mix-blend-mode: multiply;
        opacity: 0.5;
        z-index: -2;
    }

    &::after {
        z-index: -1;
        background: linear-gradient(180deg, #0D234F00 0%, #02102D 89%, #010F2B 100%);
    }
}
.single_bloc_entreprise{
    margin-bottom: 30px;
    background: linear-gradient(136deg, #449EFF 0%, #4197F5 12%, #3C86DD 32%, #326AB5 57%, #26437E 87%, #203163 100%);
    padding: 40px 50px;
    border: 2px solid white;
    border-radius: 10px;
    width: 32%;
    position: relative;
    background-size: 100%;
    transition: all .25s ease-in-out;

    &:hover {
        background-size: 200%;
        transform: scale(1.05);
    }
    @include media-breakpoint-down(lg) {
        width: 100%;
    }
    @include media-breakpoint-down(sm) {
        padding: 20px;
    }
    &.single_bloc_entreprise_full{
        width: 100%;
    }
    .single_bloc_entreprise_pic{
        margin-bottom: 20px;
    }
    .single_bloc_entreprise_pic_bg {
        position: absolute;
        opacity: 0.1;
        bottom: 20px;
        right: 20px;
        transform: scale(2.5);
        transform-origin: bottom right;
    }
    .single_bloc_entreprise_title{
        margin-bottom: 12px;
        color: #fff;
        font-size: 30px;
        line-height: 32px;
        font-weight: 700;
        @include media-breakpoint-down(xl){
            font-size: 24px;
            line-height: 26px;
        }
    }
    .single_bloc_entreprise_desc{
        p{
            color: #fff;
        }
    }
}
#strap_jobs{
    margin-top: 60px;
    margin-bottom: 80px;
    .title_page_entreprise{
        color: $primary;
    }
}
#strap_groupe{
    margin-bottom: 100px;
    .title_page_entreprise{
        color: #fff;
    }
    p,
    .bloc_home_surtitle{
        color: #fff;
    }
    .strap_groupe_header {
        padding: 30px;
        position: relative;
        text-align: center;
        &::before {
            content: '';
            position: absolute;
            background:     linear-gradient(90deg, #449EFF 0%, #4197F5 12%, #3C86DD 32%, #326AB5 57%, #26437E 87%, #203163 100%);
            width: 100vw;
            height: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;
        } 
    }
    .strap_groupe_content {
        padding: 60px 0 100px;
        position: relative;

        &::before {
            content: '';
            background-color: $primary;
            opacity: 0.73;
            mix-blend-mode: multiply;
            width: 100vw;
            height: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;
        }

        > img {
            position: absolute;
            width: 100vw;
            height: 100%;
            max-width: unset;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            z-index: -2;
            object-fit: cover;
        }        
    }
    .strap_groupe_thumb{
        position: relative;
        @include media-breakpoint-down(sm) {
            margin-bottom: 20px;
        }
        .logo_groupe{
            position: absolute;
            right: -30px;
            bottom: -40px;
            width: 300px;
            @include media-breakpoint-down(sm) {
                width: 160px;
            }
        }
    }
}
#strap_localisation{
    position: relative;
    padding-top: 52px;
    @include media-breakpoint-down(sm) {
        padding: 20px;
        .title_page_entreprise,
        .bloc_home_surtitle{
            color: $primary!important;
        }
    }
    &:before{
        position: absolute;
        background-color: #030F29;
        content: "";
        width: 100vw;
        height: 390px;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        z-index: -1;
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
    .lieux {
        justify-content: space-between;
        gap: 30px;
        @include media-breakpoint-up(xl) {
            margin: 0 calc(2% - 15px);
        }
        @include media-breakpoint-up(xxl) {
            margin: 0 calc(5% - 15px);
        }
    }
    .single_lieu{
        // margin: 0 auto;
        width: 500px;
        @include media-breakpoint-down(sm) {
            width: 300px;
        }
        .single_lieu_title{
            margin-bottom: 10px;
            font-size: 22px;
            line-height: 28px;
            font-weight: 700;
            margin-left: 20px;
        }
        p{
            color: #0d234f;
        }
        .slider_localisation{
            .slick-list,
            .slick-slide {
                height: 340px;
            }
            .slick-dots{
                top: 40px;
                bottom: 0;
                width: 20px;
                right: -20px;
                li{
                    display: block;
                    button{
                        width: 8px;
                        height: 8px;
                        border-radius: 8px;
                        background-color: #7081AB;
                        margin: auto;
                        &:before{
                            display: none;
                        }
                    }
                    &.slick-active{
                        button{
                            width: 12px;
                            height: 12px;
                            background-color: #589EF8;
                        }
                    }
                }
            }
        }
        &.single_lieu_02{
            @include media-breakpoint-up(xl) {
                margin-top: 100px;
            }
        }
    }
}

#strap_sponsor{
    padding: 60px 0 80px;
    position: relative;
    z-index: 1;

    &::before {
        content: '';
        background-image: url(/images/svg/a-propos-background.svg);
        background-position: left 0 center;
        background-repeat: no-repeat;
        background-size: 356px 465px;
        width: 356px;
        height: 465px;
        position: absolute;
        z-index: 1;
        transform: translateX(-100%);
        left: 20px;
    }

    .title_page_entreprise{
        color: $primary;
        margin-bottom: 1rem;
    }
    p{
        color: #0d234f;
    }
    .strap_sponsor_iframe {
        position: relative;
        overflow: hidden;
        aspect-ratio: 16/9;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
    }
}


/***** PAGE CONTACT *****/
body.page_contact .h1,body.page_contact h1{display:none}body.page_contact .content{position:relative}body.page_contact .content:before{background:url(/images/carte.png) no-repeat top;bottom:0;content:"";display:block;position:absolute;top:-27px;width:100%;z-index:-1}.formulaire input[type=text],.formulaire textarea{border:2px solid #0d234f;border-radius:5px;height:56px;margin-bottom:20px;padding-left:30px;width:100%}.formulaire input[type=text]::-moz-placeholder,.formulaire textarea::-moz-placeholder{color:#0d234f}.formulaire input[type=text]::placeholder,.formulaire textarea::placeholder{color:#0d234f}.formulaire textarea{height:156px!important;padding-top:25px}.formulaire .multi_radio{margin-right:12px}.formulaire .single_input_flex{width:48%}.page_contact_content{background-color:#fff;border-radius:10px;margin-bottom:30px;padding:50px;width:730px}.page_contact_content .form_contact_title{color:#0d234f;font-size:40px;font-weight:700;line-height:47px}

.formulaire select{border:2px solid #0d234f;border-radius:5px;height:56px;margin-bottom:20px;padding-left:30px;width:100%;background:none}
@include media-breakpoint-down(sm) {
    .formulaire .single_input_flex{
        width: 100%;
    }
    .page_contact_content{
        padding: 20px;
    }
}
/***** HOMEPAGE *****/
#background-video {
    height: 100%;
    width: 100vw;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
}
body.homepage #sub_menu_header ul{
    margin-bottom: 0;
}
.title_content_home{
    font-size: 40px;
    font-weight: 700;
}
.home_strap_title{
    font-size: 30px;
    color: $primary;
    font-weight: 700;
}
.subtitle_content_home{
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
}
.bloc_home_surtitle{
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
}
.bloc_home_title{
    font-size: 35px;
    font-weight: 700;
    @include media-breakpoint-down(lg){
        font-size: 24px;
    }
    @include media-breakpoint-down(md){
        br{
            display: none;
        }
    }
}
#strap_home_top{
    position: relative;
    height: 740px;
    width: 100vw;
    // background:url(/images/bg_home.jpg) no-repeat top center;
    // background-size: cover;
    // width: 100%;
    // height: 640px;
    // &:before{
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    //     right: 0;
    //     height: 270px;
    //     content: '';
    //     background: rgb(13,35,79);
    //     background: linear-gradient(0deg, rgba(13,35,79,1) 0%, rgba(2,16,45,0.3) 70%, rgba(1,15,43,0) 100%);
    // }
    .home_title_thumb{
        font-size: 48px;
        font-weight: 700;
        line-height: 56px;
        @include media-breakpoint-down(lg) {
            font-size: 40px;
            line-height: 48px;
        }
        @include media-breakpoint-down(sm) {
            font-size: 30px;
            line-height: 38px;
        }
    }
    @include media-breakpoint-down(lg) {
        height: 500px;
    }
    @include media-breakpoint-down(sm) {
        height: 400px;
    }
}
#strap_entreprise_home{
    position: relative;
    @include media-breakpoint-down(md) {
        background-color: #0d234f;
        padding-bottom: 30px;
    }
    &:before{
        content: '';
        position: absolute;
        background-color: $primary;
        left: 0;
        right: 0;
        top: 80px;
        height: 455px;
        z-index: -1;
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
    .strap_entreprise_home_container{
        margin-top: -80px;
        @include media-breakpoint-down(md) {
            margin-top: 0;
        }
    }
    .strap_entreprise_home_content{
        strong{
            font-size: 22px;
            color: $secondary;
        }
        p{
            color: #fff;
        }
    }
    .strap_entreprise_home_thumb{
        height: 610px;
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}
#strap_prestations{
    // margin-top: 65px;
    margin-bottom: 60px;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    
    @include media-breakpoint-up(lg){
        margin-top: -165px;
    }

}
.single_prestation{
    margin-bottom: 20px;
    background-color: $primary;
    // height: 324px;
    border-bottom: 5px solid #449EFF;
    border-radius: 10px;
    overflow: hidden;

    @include media-breakpoint-up(lg){
        &.col-lg-4 {
            width: calc(33.33333333% - 20px);
        }
    }

    
    .single_prestation_link{
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    &:hover{
        img{
            transition: all 0.4s;
            transform: scale(1.1);
        }
    }
    .single_prestation_texte{
        position: relative;
        width: 100%;
        padding: 10px 30px;
        @include media-breakpoint-down(xl){
            padding: 20px;
        }
        // @include media-breakpoint-down(lg) {
        //     width: 60%;
        // }
        @include media-breakpoint-down(md) {
            width: 100%;
        }
        &:after{
            content: '';
            width: 34px;
            height: 16px;
            background: url('/images/pic_arrow.png') no-repeat;
            position: absolute;
            right: 30px;
            bottom: 26px;
            @include media-breakpoint-down(xl){
                bottom: 10px;
                right: 18px;
            }
        }
        h2{
            font-size: 24px;
            font-weight: 700;
            line-height: 1.2;
            color: #fff;
        }
        p{
            color: #fff!important;
        }
    }
    .single_prestation_img{
        width: 100%;
        transition: all 0.4s;
        overflow: hidden;
        max-height: 245px;
        // @include media-breakpoint-down(lg) {
        //     width: 40%;
        // }
        @include media-breakpoint-down(md) {
            display: none;
        }
        &.single_prestation_img_left img{
            border-radius: 10px 0 0 10px;
        }
        &.single_prestation_img_right img{
            border-radius: 0 10px 10px 0;
        }
    }
}
#strap_engagements{
    position: relative;
    
    &::before {
        content: '';
        background-image: url(/images/svg/a-propos-background.svg);
        background-position: left 0px center;
        background-repeat: no-repeat;
        background-size: 520px 685px;
        width: 520px;
        height: 685px;
        position: absolute;
        z-index: 1;
        transform: translateX(-100%);
        left: 60px;
    }
    .single_engagement{
        margin-bottom: 30px;
        background: linear-gradient(136deg, #449EFF 0%, #4197F5 12%, #3C86DD 32%, #326AB5 57%, #26437E 87%, #203163 100%);
        border: 2px solid white;
        padding: 22px;
        width: 24%;
        border-radius: 10px;
        transition: all .25s ease-in-out;

        &:hover {
            background-size: 200%;
            transform: scale(1.05);
        }
        @include media-breakpoint-down(lg) {
            width: 48%;
        }
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
        .single_engagement_picto{
            height: 60px;
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            filter: brightness(0) invert(1);
        }
        .single_engagement_txt{
            color: #fff;
            p, h2{
                color: #fff;
            }
            h2{
                font-size: 25px;
                font-weight: 700;
                line-height: 26px;
                @include media-breakpoint-down(xl){
                    font-size: 20px;
                    line-height: 22px;
                }
            }
        }
    }
}
#strap_contact_home{
    width: 100%;
    height: 226px;
    margin-bottom: 60px;
    img{
        display: block;
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
    .strap_contact_home_content{
        display: flex;
        justify-content: space-around;
        align-items: center;
        top: 0;
        left: 12px;
        right: 12px;
        bottom: 0;
        background: rgba(13,35,79, 0.5);
        border-radius: 10px;
        @include media-breakpoint-down(lg){
            flex-wrap: wrap;
            padding: 20px;
        }
    }
}
#strap_partenaires{
    position: relative;
    padding-bottom: 68px;
    &:before{
        position: absolute;
        content: '';
        background-color: #010F2B;
        left: 0;
        right: 0;
        bottom: 0;
        height: 104px;
        z-index: -1;
    }
    .partenaires_title{
        font-size: 30px;
        font-weight: 700;
        color: #6D81AF;
    }
    #slider_partenaires{
        background-color: #fff;
        border: 1px solid #c2c3c7;
        border-radius: 100px;
        @include media-breakpoint-down(sm) {
            max-width: 90%;
        }
        .slick-arrow{
            width: 32px;
            height: 32px;
            &.slick-prev{
                background: url('/images/pic_prev.png') no-repeat;
            }
            &.slick-next{
                background: url('/images/pic_next.png') no-repeat;
            }
            &:before{
                display: none;
            }
        }
    }
    .slick-slide{
        border-right: 1px solid #c2c3c7;
        img{
            padding: 14px 0;
            max-height: 70px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.sticky_actu_home{
    .events--item{
        position: relative;
        height: 325px;
        border-radius: 5px;
        margin: 10px;
        overflow: hidden;
        &:hover{
            transition: all 0.4s;
            img{
                transform: scale(1.1);
            }
        }
        img{
            border-radius: 5px;
            transition: all 0.4s;
        }
        .events--item-link{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        .events--item-title{
            font-size: 20px;
            color: #fff;
        }
        .sticky_actu_home_content{
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: rgb(13,35,79);
            background: linear-gradient(0deg, rgba(13,35,79,1) 0%, rgba(2,16,45,0.3) 70%, rgba(1,15,43,0) 100%);
            border-radius: 5px;
            padding: 0 30px 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
          
        }
        .events--item-date{
            display: inline-block;
            margin-bottom: 10px;
            background: #fff;
            color: $primary;
            font-size: 13px;
            border-radius: 4px;
            padding: 4px;
        }
    }
}


.content {

}

.main {

}

.right-bar {

}

.footer {

}


/** RESPONSIVE **/

@include media-breakpoint-up(sm) {

}

@include media-breakpoint-up(md) {

}

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(xl) {

}

@include media-breakpoint-up(xxl) {

}
