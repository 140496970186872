
// start section histoire
.histoire {
    color: $primary;
    background: white;
    padding: 80px 0 100px;
    // overflow: hidden;
    
    @include media-breakpoint-up(lg) {
        padding: 140px 0;
    }
}

.histoire__header {
    position: relative;
    line-height: 1;
    margin-bottom: 4rem;

    &::before {
        content: 'Notre histoire';
        text-transform: uppercase;
        color: rgba(68, 158, 255, 0.07);
        width: max-content;
        position: absolute;
        font-size: 8rem;
        left: -160px;
        transform: translateY(-50%);
        top: 50%;
    }
    
    @include media-breakpoint-up(md) {
        &::before {
            font-size: 10rem;
            left: -240px;
            margin-bottom: 6rem;
        }
    }
    
    @include media-breakpoint-up(xl) {
        &::before {
            font-size: 13.125rem;
            left: -390px;
        }
    }
}

.histoire__title {
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 0;
    position: relative;
    display: block;
    
    @include media-breakpoint-up(lg) {
        font-size: 3.438rem;
    }
}

.histoire__subtitle {
    font-size: 1.125rem;
    margin-bottom: 0;
    position: relative;

    @include media-breakpoint-up(lg) {
        font-size: 1.25rem;
    }
}

.content p.histoire__subtitle {
    color: $primary;
}

.histoire__dates {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-content: space-around;
    position: relative;
    font-size: 0.875rem;

    &::before {
        content: '';
        background: $secondary;
        width: 3px;
        height: calc(100% + 50px);
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        top: calc(50% + 15px);
    }
    
    @include media-breakpoint-up(lg) {
        font-size: 1rem;
        height: 525px;
        flex-wrap: wrap;
        align-content: space-between;

        &::before {
            width: 100vw;
            height: 3px;
            top: 50%;
        }
    }
}

.histoire__dates__item {
    width: calc(50vw - 30px);
    display: flex;
    flex-direction: column;
    border-bottom: 1px dashed $secondary;
    padding: 0 10px 0 0;

    @include media-breakpoint-up(sm) {
        width: calc(270px - 30px); // half width of container without padding
    }

    @include media-breakpoint-up(md) {
        width: calc(360px - 30px); // half width of container without padding
    }

    @include media-breakpoint-up(lg) {
        border-bottom: 0;
        border-left: 1px dashed $secondary;
        padding: 0 0 0 18px;
        width: 184px;
        height: calc(50% - 16px);
    }
}

.histoire__dates__item:nth-child(even) {
    align-self: flex-end;
    align-items: flex-end;
    padding: 0 0 0 10px;

    @include media-breakpoint-up(lg) {
        margin-top: auto;
        margin-left: 44px;
        align-items: flex-start;
        // justify-content: flex-end;
        padding: 50px 0 0 18px;
    }

    @include media-breakpoint-up(xl) {
        margin-left: 88px;
    }

    @include media-breakpoint-up(xxl) {
        margin-left: 130px;
    }
}

.histoire__dates__item:nth-child(odd) {
    margin-top: -100px;

    @include media-breakpoint-up(lg) {
        margin: 0;
    }
}

.histoire__dates__item:first-child {
    margin-top: 0;
}

.histoire__dates__item p {
    width: calc(50vw - 30px);
    max-width: 140px;
    color: $primary;

    @include media-breakpoint-up(lg) {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.histoire__dates__item strong {
    font-family: 'IBM Plex Mono';
    font-size: 1.5rem;
    position: relative;
    color: $primary;

    &::before {
        content: '';
        background: $secondary;
        width: 0%;
        height: 13px;
        position: absolute;
        bottom: 4px;
        left: -5px;
        z-index: -1;
        transition: width .25s ease-in-out;
    }

    @include media-breakpoint-up(lg) {
        font-size: 2.5rem;
    }
}

.histoire__dates__item:hover strong::before {
    width: calc(100% + 10px);
}
// end section histoire
