/*	--------------------------------------------------
	--------------------------------------------------
		Communauté et ses widgets :
		notations, commentaires, tags
--------------------------------------------------
--------------------------------------------------*/

/* -------------------------------------------- */
/* ETOILES DES NOTATIONS                        */
/* -------------------------------------------- */
.ui-stars-cancel, .ui-stars-star {
	display: inline-block;
	width: 17px;
	height: 15px;
	text-indent: -999em;
	cursor: pointer;
	background: transparent;
	overflow: hidden;
}
.ui-stars-cancel, .ui-stars-cancel a { background: url(/common_images/skin001/star-delete.gif) no-repeat 0 -16px; }
.ui-stars-star, .ui-stars-star a { background: url(/common_images/skin001/star.gif) no-repeat 0 0; }
.ui-stars-cancel a, .ui-stars-star a {
	display: block;
	width: 16px;
	height: 100%;
	background-position :0 0;
}
.ui-stars-star-on a { background-position: 0 -16px!important; }
.ui-stars-star-hover a { background-position: 0 -32px; }
.ui-stars-cancel-hover a { background-position: 0 -16px; }
.ui-stars-cancel-disabled a, .ui-stars-star-disabled,
.ui-stars-star-disabled a { cursor: default !important; }
.ui-stars-star {
	background: transparent!important;
	overflow: hidden!important;
}

/** RESPONSIVE **/

// Small devices (landscape phones, 34em and up)
@include media-breakpoint-up(sm) {

}

// Medium devices (tablets, 48em and up)
@include media-breakpoint-up(md) {

}

// Large devices (desktops, 62em and up)
@include media-breakpoint-up(lg) {

}

// Extra large devices (large desktops, 75em and up)
@include media-breakpoint-up(xl) {

}
