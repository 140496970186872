/*	--------------------------------------------------
	--------------------------------------------------
		Widgets :
	propriétés générales, texte, actu, rss,
	banniere, calendrier, raccourcis, météo, sondage,
	recommander le site, newsletter, publicité

--------------------------------------------------
--------------------------------------------------*/

/* commons */
.box {}
.box--title {}

//no masque les titres des widget avec la classe "no-title"
.no-title .box--title{display: none}

/* text (/boxes/text/box_text.html) */
.box-txt {}

/* shortcuts header (/boxes/shortcut/header_shortcut.html) */
.box-header-links {}
.box-header-links--list {}
.box-header-links--item {}
.box-header-links--link{}

/* shortcuts aside (/boxes/shortcut/tools_shortcut.html) */
.box-aside-links {}
.box-aside-links--list {}
.box-aside-links--item {}
.box-aside-links--link {}

/* shortcuts footer (/boxes/shortcut/footer_shortcut.html) */
.box-footer-links {}
.box-footer-links--list {}
.box-footer-links--item {}
.box-footer-links--link {}

/* last elements (/boxes/last_elements/last_elements.html) */
.box-last-elts {}
.box-last-elts--list {}
.box-last-elts--item {}
.box-last-elts--article {}
.box-last-elts--title {}
.box-last-elts--date {}
.box-last-elts--img {}
.box-last-elts--desc {}
.box-last-elts--link{}

/* newsletter (/newsletters/box_newsletters.html) */
.box-nl {}
.box-nl--desc {}
.box-nl--form {}
.box-nl--label {}
.box-nl--input {}
.box-nl--submit {}


/* search (/search/box_search.html) */
.box-search {}
.box-search--form {}
.box-search--label {}
.box-search--input {}
.box-search--submit {}
.box-search--advanced {}

/* box-social (/z-templates/social.tmpl.html) */
.box-social {}
.box-social--list{margin-bottom: 0;}
.box-social--item {
    display: inline-block;
    vertical-align: middle;
}
.box-social--link {
	color: $primary;
	width: 30px;
	height: 30px;
	text-align: center;
	padding-top: 7px;
	display: block;
	text-decoration: none;
}
.box-social--facebook {}
.box-social--twitter {}
.box-social--google {}


#sub_menu_header{
	@include media-breakpoint-down(lg) {
        display: none;
    }
	background-color: $primary;
	ul{
		display: flex;
		justify-content: space-around;
		a{
			display: block;
			padding: 25px 0;
			color: #fff;
			font-weight: 700;
			font-size: 20px;
			text-decoration: none;
			&:hover{
				text-decoration: underline;
			}
			@include media-breakpoint-down(xl){
				font-size: 15px;
				padding: 20px 0;
			}
		}
	}
}
.menu_footer{
	strong{
		color: #fff;
	}
    ul{
        list-style: none;
        padding-left: 0;
		li{
			font-size: 14px;
		}
		a{
			color: $secondary;
			text-decoration: none;
		}
    }
}
.widget_adresse_footer__left {
	margin-right: 2em;
}
/** RESPONSIVE **/

// Small devices (landscape phones, 34em and up)
@include media-breakpoint-up(sm) {

}

// Medium devices (tablets, 48em and up)
@include media-breakpoint-up(md) {

}

// Large devices (desktops, 62em and up)
@include media-breakpoint-up(lg) {

}

// Extra large devices (large desktops, 75em and up)
@include media-breakpoint-up(xl) {

}
