// Theme variables

// COLORS
$primary:       #0D234F;
$secondary:     #449eff;

$body-color: $secondary;

// FONTS
$font-family-sans-serif: 'Outfit', sans-serif;

// FORMULAIRES
$input-placeholder-color:#CCC;
